body {
  background-color: #ffffff; /* Changed to match the header background */
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
}

.App header {
  background-color: #ffffff;
  height: 10vh;
  color: black;
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 0 20px; /* Increased padding */
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.sign-in-shadow {
  position: fixed; /* Keep it at the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  height: 10vh; /* Match the height of the header */
  background-color: #ffffff; /* Same white color as the header */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  z-index: 10; /* Ensure it's above other elements */
}

.sign-in-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: calc(90vh - 10vh); /* Adjust height if necessary */
  margin-top: 20px; /* Optional margin to add spacing from the top */
}

body.signed-in .sign-in-shadow {
  display: none; /* Hide shadow when signed in */
}

.sign-out {
  color: white;
  background-color: #dc3545;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  outline: none;
  margin-right: 5px; /* Added margin to move it away from the edge */
}

.sign-out:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.sign-out:active {
  background-color: #bd2130;
}

.App section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  background-color: #f8f9fa;
}

main {
  padding: 10px;
  padding-top: 12vh; /* Added padding-top to avoid overlap */
  height: calc(100vh - 20vh);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth; /* Smooth scroll to last message */
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #d0d0d0;
}

main::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}

form {  
  height: 10vh;  
  position: fixed;  
  bottom: 0;  
  background-color: #ffffff;  /* Match the header */
  width: 100%;  
  display: flex;  
  font-size: 1.5rem;  
  z-index: 1000; /* Ensure it appears above other elements */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.2); /* Added shadow */
}


/* Shadow effect only when signed out */
body.signed-out form {
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1), 0 4px 10px rgba(0,0,0,0.2); /* Added shadow */
}

form button {  
  width: 20%;  
  background-color: #007bff;  
  border: none;  
  color: white;  
  font-size: 1.25rem;  
  cursor: pointer;  
  transition: background-color 0.3s ease;  
  z-index: 1001; /* Ensure it appears above other elements */
}

form button:hover {
  background-color: #0056b3;
}

input {  
  line-height: 1.5;  
  width: 100%;  
  font-size: 1.5rem;  
  background: #ffffff;  
  color: black;  
  outline: none;  
  border: 1px solid #ced4da;  
  padding: 0 10px;  
  border-radius: 0px;  
  transition: border-color 0.3s ease;  
  z-index: 1002; /* Ensure it appears above other elements */
}



button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sign-in {
  color: #fff;
  background: linear-gradient(135deg, #6dd5fa, #2980b9); /* Subtle gradient for a professional touch */
  max-width: 400px;
  margin: 0 auto;
  border-radius: 10px; /* Slightly sharper corners for a cleaner look */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px 30px; /* Increased padding for a more substantial feel */
  font-size: 1.2rem;
  text-align: center;
  outline: none;
  border: none; /* Remove default border */
  transition: transform 1s ease, box-shadow 1s ease;
}

.sign-in:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}


ul, li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: black;
  text-align: center;
}

.message {
  display: flex;
  align-items: flex-start;
  flex-direction: column;  /* Stack the username and message vertically */
  margin-bottom: 20px;     /* Adds space after each message block */
  position: relative;      /* Allow positioning of the message-time */
}



.message-time {
  font-size: 12px;
  color: gray;
  text-align: right;
  margin-top: 5px;
  opacity: 0; /* Initially hidden */
  animation: fadeOut 0.5s ease forwards; /* Start with fade out (hidden) */
}

.message:hover .message-time {
  animation: fadeIn 0.5s ease forwards; /* Fade in on hover */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


.sent .message-time {
  margin-left: 0;
  margin-top: 5px;
  align-self: flex-start;
  text-align: right;
  position: absolute;
  right: 5px;
  bottom: -18px;
}

.received .message-time {
  margin-right: 0;
  margin-top: 5px;
  align-self: flex-start;
  text-align: right;
  position: absolute;
  left: 5px;
  bottom: -18px;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.message-meta {
  color: grey;
  font-weight: normal;
  margin-left: 55px;
  margin-bottom: 0px;      /* Margin between name and message */
}



.message img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;  /* Adjust for spacing between the avatar and message */
}
.message p {
  display: inline-block; /* Keep the bubble only as wide as its content */
  background: #e5e5ea; /* Background color for received messages */
  color: black; /* Text color for received messages */
  padding: 8px 12px; /* Padding inside the bubble */
  border-radius: 10px; /* Rounded corners */
  max-width: 40vw; /* Limit the bubble to 50% of the viewport width */
  margin: 0; /* Remove any default margins */
  word-wrap: break-word; /* Allows long words to break and wrap */
  white-space: pre-wrap; /* Preserve spaces and line breaks */
  text-align: left; /* Align text to the left */
}

.sent {
  flex-direction: row-reverse;
  align-items: flex-end;
  padding: 5px;
}

.sent .message-time {
  margin-left: 0;
  margin-top: 5px; /* Space between the message and time */
  align-self: flex-start; /* Align timestamp to be under the avatar */
  text-align: right; /* Right-align the timestamp */
  position: absolute; /* Position it relative to the container */
  right: 5px; /* Place it beneath the image */
  bottom: -18px; /* Move it below the message bubble */
}

.received .message-time {
  margin-right: 0;
  margin-top: 5px; /* Space between the message and time */
  align-self: flex-start; /* Align timestamp to be under the avatar */
  text-align: right; /* Right-align the timestamp */
  position: absolute; /* Position it relative to the container */
  left: 5px; /* Place it beneath the image */
  bottom: -18px; /* Move it below the message bubble */
}

.sent p {
  color: white;
  background: #007bff; /* Background for sent messages */
  align-self: flex-end;
  text-align: left; /* Align text to the left */
  max-width: 40vw; /* Limit the bubble to 40% of the viewport width */
  margin: 0; /* No margins around the message bubble */
  padding: 8px 12px; /* Padding inside the message bubble */
  border-radius: 10px; /* Rounded corners for the message bubble */
  word-wrap: break-word; /* Ensure long words wrap */
  position: relative;
}




.received p {
  position: relative;
  background-color: #e5e5ea; /* Background color for received messages */
  color: black;
  padding: 8px 12px;
  border-radius: 10px;
  max-width: 40vw;
  margin: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
  box-sizing: border-box; /* Makes sure padding is included in width */
}



img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: -1px 5px;
}

.sent img {
  margin-left: 7px;            /* Remove any margin on the left side for sent messages */
  margin-right: 8px;         /* Space between image and message bubble */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  form {
    font-size: 1rem; /* Reduce font size */
  }

  input {
    font-size: 1rem; /* Reduce input font size */
  }

  form button {
    font-size: 1rem; /* Reduce button font size */
    width: 30%; /* Adjust button width */
  }

  .sign-in {
    max-width: 90%; /* Allow sign-in to take more width on smaller screens */
  }
}

button {
  background-color: #56abff; /* Lighter default button color */
  border: none;
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 1s ease, box-shadow 1s ease; /* Smooth transition for background and shadow */
}

.active-button {
  background-color: #3399ff; /* Lighter shade when active */
  box-shadow: 0 0 10px rgba(51, 153, 255, 0.5); /* Subtle glow effect */
}

.active-button:hover {
  background-color: #007bff; /* Default color on hover */
}

/* Optional: Transition for the disabled state */
button:disabled {
  background-color: #57abff; /* Keep the same color when disabled */
  opacity: 0.5; /* Slightly faded */
  cursor: not-allowed;
}